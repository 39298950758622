<template>
  <div
    class="boost-btn"
    @click="emits('handleClick')"
  >
    <s-button
      :type="['primary']"
      :style="{ color: '#fff' }"
      :loading="isBoostBtnLoading"
    >
      {{ buttonText }}
    </s-button>
    <Icon
      v-if="data.isSupportExpand"
      class="boost-icon"
      :name="iconName"
      size="36px"
      :is-rotate="gb_cssRight"
    />
  </div>
</template>
<script setup>
import { defineEmits, inject, computed } from 'vue'
import { boostedBtnIcon } from '../../utils.js'
import { Icon } from '@shein-aidc/icon-vue3'

const emits = defineEmits(['handleClick'])
const isBoostBtnLoading = inject('isBoostBtnLoading')

const gb_cssRight = computed(() => {
  const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
  return GB_cssRight
})
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  buttonText: {
    type: String,
    default: '',
  },
  handleClick: {
    type: Function,
    default: () => {}
  }
})

const iconName = boostedBtnIcon[props.data.couponColorType]
</script>
<style scoped>
.head-right .boost-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;

  margin-bottom: 2px;
  border-radius: 3px;
}

.head-right .sui-button-common {
  background: var(--core-color);
  border-radius: 2px;
  border: none;
  max-width: 90px;
  height: 28px;
  padding: 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 600;
  min-width: 51px;
  line-height: normal;
}

.boost-icon {
  position: absolute;
  right: -10px;
  top: -6px;
  scale: 1.2;
  &:hover {
    cursor: pointer;
  }
}
</style>

