<template>
  <div :class="[!hasTags && 'noneInfo', 'coupon_type']">
    <!-- 劵类型&提示 -->
    <CouponInfo />
    <!-- 劵数量 -->
    <CouponCount v-if="!surpriseBonus" />
    <!-- 劵右上角标签 -->
    <CouponLabel v-else />
  </div>
</template>
<script>
export default {
  name: 'CouponType'
}
</script>

<script setup>
import CouponInfo from './CouponInfo'
import CouponCount from './CouponCount'
import CouponLabel from './CouponLabel'

import { inject } from 'vue'

const hasTags = inject('hasTags')
let originData = inject('couponDataInfo')
//解构数据
let { surpriseBonus = '' } = originData || {}
</script>

<style lang="less" scoped>
.coupon_type {
  height: 20px;
  display: flex;
  align-items: flex-start;
  gap: 14px;
  justify-content: space-between;
}

.noneInfo {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
