<template>
  <s-dialog
    v-model:visible="isShowCouponGoodsDrawer"
    v-expose="reportData"
    :append-to-body="true"
    :show-close="true"
    :close-on-click-modal="true"
  >
    <template 
      v-if="title"
      #title
    >
      {{ title }}
    </template>
    <div 
      v-if="isForBff && subTitle?.length"
      class="coupon-goods__title"
    >
      <li
        v-for="(item, index) in subTitle"
        :key="index"
        v-html="xssEncode(item)"
      ></li>
    </div>
    <div 
      v-else-if="!isForBff"
      class="coupon-goods__title"
      v-html="xssEncode(subTitle, xssOptions)"
    >
    </div>
    <div class="coupon-goods__list">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="coupon-goods__item"
      >
        <img 
          class="item-img" 
          :src="transformImg({ img: item.goodsImg })" 
        />
        <p
          class="item-price"
          :class="{ 'discount-price': item.hasDiffPrice || false }"
        >
          {{ item.goodsPrice }}
        </p>
      </div>
    </div>
  </s-dialog>
</template>

<script>
import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// eslint-disable-next-line no-unused-vars
import { expose as vExpose } from 'public/src/pages/common/analysis/directive.js'
import xssEncode from 'xss'

daEventCenter.addSubscriber({ modulecode: '2-38' })
export default {
  name: 'CouponGoodsPop',
  emits: ['update:isShow'],
  inject: ['couponDataInfo', 'couponConfig', 'isForBff'],
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      // 自定义xss过滤规则
      xssOptions: {
        whiteList: {
          a: ['href', 'title', 'target'],
          img: ['src', 'alt'],
          p: ['class', 'style'],
          span: ['class', 'style'],
          li: ['class', 'style'],
          div: ['class', 'style'],
        }
      }
    }
  },
  computed: {
    isShowCouponGoodsDrawer: {
      get () {
        return this.isShow || false
      },
      set (val) {
        this.$emit('update:isShow', val)
      }
    },
    reportData () {
      return {
        id: '2-38-5',
        data: {
          activity_from: this.couponConfig?.activityFrom,
          coupon_id: this.couponDataInfo?.id,
          coupon_code: this.couponDataInfo?.coupon,
          if_useful: this.couponDataInfo?.satisfiedRange ? 1 : 0,
          sku_id: this.list.filter(item => item?.goodsSn)?.map(item => item?.goodsSn).join(','),
        }
      }
    }
  },
  methods: {
    xssEncode,
    transformImg,
    promotionCutPriceShow (item) {
      if (item.reduce_price) {
        return Object.keys(item.reduce_price).length > 0
          && item.reduce_price.siteFrom == this.locals.SiteUID
          && item.reduce_price.reduceAmount.amount > 0
      }
      return false
    },
  }
}
</script>

<style lang="less">
.coupon-goods__title {
  padding: 12px;
  color: #666;
  font-size: 14px;

  li {
    list-style: disc;
    text-align: left;
  }
}
.coupon-goods__list {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  .coupon-goods__item {
    .txt-l();
    width: calc((100% - 30px) / 4);
    .margin-r(10px);
    margin-bottom: 12px;
    &:nth-child(4n) {
      .margin-r(0);
    }
    .item-img {
      width: 100%;
      height: 128px;
      margin-bottom: 4px;
    }
    .item-price {
      font-weight: 700;
      font-size: 12px;
      color: @sui_color_main;
    }
    .discount-price {
      color: @sui_color_discount;
    }
  }
}
</style>
