<template>
  <div class="coupon-surprise">
    <div class="icon"></div>

    <div class="text">
      {{ language.SHEIN_KEY_PC_34998 || surpriseBonus }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'CouponLabel',
}
</script>
<script setup>
import { inject } from 'vue'

const language = inject('couponLanguage')
let originData = inject('couponDataInfo')

//解构数据
let { surpriseBonus = '' } = originData || {}
</script>

<style lang="less" scoped>
.coupon-surprise {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-family: "SF UI Text", sans-serif;

  .icon {
    display: inline-block;
    background-color: var(--label-bg-color, #fee4de);
    width: 10px;
    height: 14px;
    clip-path: path(
      'M0 0H91C93.2091 0 95 1.79086 95 4V14C95 14 13.9411 14 9 14C4.0589 14 4.11765 0 0 0Z'
    );
    transform: scaleX(1)/*rtl: scaleX(-1)*/;
  }

  .text {
    height: 14px;
    overflow: hidden;
    line-height: 14px;
    padding: 0 6px 0 0;
    border-top-right-radius: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--label-bg-color, #fee4de);
    color: var(--label-text-color, #f6451e);
  }
}
</style>
